import {
  useCurrentProjectApiClient,
  useLoadAreaContents,
} from "@/components/common/project-provider/project-loading-context";
import { selectAreaContents } from "@/store/area-contents-selectors";
import {
  addAreaContents,
  ENTIRE_PROJECT_KEY,
} from "@/store/area-contents-slice";
import {
  AreaContentType,
  CAPTURES_ELEMENT_TYPES,
  ROOMS_ELEMENT_TYPES,
} from "@/store/area-contents-types";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { FaroText, TextField } from "@faro-lotv/flat-ui";
import {
  IElement,
  IElementImg360,
  IElementTypeHint,
  isIElementImg360,
  isValid,
} from "@faro-lotv/ielement-types";
import {
  selectChildrenDepthFirst,
  selectIElement,
  selectIsLoadingSubtrees,
  TreeData,
} from "@faro-lotv/project-source";
import { Skeleton } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useLayoutEffect, useMemo, useState } from "react";
import { TREE_NODE_HEIGHT, TreeNodeProps } from "../tree-node";
import { TreeWrapper } from "../tree-wrapper";
import { CaptureTree } from "./capture-tree";
import { CaptureTreeNode } from "./capture-tree-node";

type CaptureTreePanelProps = {
  tree: TreeData[];
};

function isValidPanoWithText(
  e: IElement | undefined,
  searchText: string,
): e is IElement {
  return (
    isValid(e) &&
    e.typeHint !== IElementTypeHint.odometryPath &&
    e.name.toLowerCase().includes(searchText.toLowerCase())
  );
}

export function CaptureTreePanel({ tree }: CaptureTreePanelProps): JSX.Element {
  const [searchText, setSearchText] = useState("");
  const isProjectLoading = useAppSelector(selectIsLoadingSubtrees);
  const isLoadingArea = useLoadArea(isProjectLoading);
  const isLoadingAreaData = useLoadAreaContents(undefined, isProjectLoading);

  const isLoading = isProjectLoading || isLoadingArea || isLoadingAreaData;
  const captures = useAppSelector(
    selectAreaContents(ENTIRE_PROJECT_KEY, AreaContentType.captures),
  );
  const rooms = useAppSelector(
    selectAreaContents(ENTIRE_PROJECT_KEY, AreaContentType.rooms),
  );

  const store = useAppStore();
  const searchTree = useMemo(() => {
    const state = store.getState();

    const startTime = Date.now();

    const panos: IElementImg360[] = [];
    for (const elementInVolume of [...captures, ...rooms]) {
      const element = state.iElements.iElements[elementInVolume.elementId];
      if (!element) continue;
      panos.push(...selectChildrenDepthFirst(element, isIElementImg360)(state));
    }

    const unique = [
      ...new Set(
        panos
          .map((pano) => selectIElement(pano.parentId)(state))
          .filter((e) => isValidPanoWithText(e, searchText)),
      ),
    ];

    const nodes: TreeData[] = unique.map((element) => ({
      id: element.id,
      label: element.name,
      element,
      children: null,
      directParent: element.parentId
        ? state.iElements.iElements[element.parentId]
        : undefined,
    }));

    console.log(`Filtered elements in ${Date.now() - startTime} ms`);

    return nodes;
  }, [captures, rooms, searchText, store]);

  return (
    <Stack component="div" sx={{ height: "100%" }}>
      <TextField
        text={searchText}
        onTextChanged={setSearchText}
        placeholder="Search"
        controlStyle={{ width: "100%", pr: 1 }}
      />
      <Box component="div" sx={{ flex: 1 }}>
        {searchText.length > 0 ? (
          <CatpureTreeSearchResult
            tree={searchTree}
            isLoading={isLoading}
            onClicked={() => setSearchText("")}
          />
        ) : (
          <TreeWrapper>
            <CaptureTree tree={tree}>{CaptureTreeNode}</CaptureTree>
          </TreeWrapper>
        )}
      </Box>
    </Stack>
  );
}

type CatpureTreeSearchResultProps = {
  /** The elements to should in the result list */
  tree: TreeData[];
  /** A flag specifying if the project is still being loaded */
  isLoading: boolean;
  /** Callback executed when an element is clicked */
  onClicked(): void;
};

/** @returns A tree showing the result of the search */
function CatpureTreeSearchResult({
  tree,
  isLoading,
  onClicked,
}: CatpureTreeSearchResultProps): JSX.Element {
  return (
    <Box component="div" sx={{ mt: 1, height: "100%" }}>
      <FaroText variant="heading14">
        {tree.length ? `Found ${tree.length} elements` : "No elements found"}
      </FaroText>
      <Stack sx={{ height: "100%" }}>
        {isLoading && (
          <Stack gap={1}>
            <Skeleton
              variant="rectangular"
              width="100%"
              height={TREE_NODE_HEIGHT}
            />
            <Skeleton
              variant="rectangular"
              width="100%"
              height={TREE_NODE_HEIGHT}
            />
            <Skeleton
              variant="rectangular"
              width="100%"
              height={TREE_NODE_HEIGHT}
            />
          </Stack>
        )}
        <TreeWrapper>
          <CaptureTree tree={tree}>{SearchTreeNode}</CaptureTree>
        </TreeWrapper>
      </Stack>
    </Box>
  );
}

function useLoadArea(isAlreadyLoading: boolean): boolean {
  // Need additional null state to distinguish from loading the "entire project".
  const [isLoading, setIsLoading] = useState(false);
  const projectApi = useCurrentProjectApiClient();
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    if (isAlreadyLoading) return;

    const ac = new AbortController();

    setIsLoading(true);
    Promise.allSettled([
      projectApi.getAllIElementsByVolume(
        {
          elementTypes: CAPTURES_ELEMENT_TYPES,
          signal: ac.signal,
        },
        (data) => {
          dispatch(
            addAreaContents({
              areaContentsKey: ENTIRE_PROJECT_KEY,
              type: AreaContentType.captures,
              elements: data,
            }),
          );
        },
      ),
      projectApi.getAllIElementsByVolume(
        {
          elementTypes: ROOMS_ELEMENT_TYPES,
          signal: ac.signal,
        },
        (data) => {
          dispatch(
            addAreaContents({
              areaContentsKey: ENTIRE_PROJECT_KEY,
              type: AreaContentType.rooms,
              elements: data,
            }),
          );
        },
      ),
    ]).finally(() => {
      if (!ac.signal.aborted) {
        setIsLoading(false);
      }
    });

    return () => {
      ac.abort();
    };
  }, [dispatch, projectApi, isAlreadyLoading]);

  // Explicit loading state is too slow. The hook needs to return true immediately when the area changes.
  return isLoading;
}

function SearchTreeNode({ node, style }: TreeNodeProps<TreeData>): JSX.Element {
  return <CaptureTreeNode node={node} style={style} collapseIcon />;
}
